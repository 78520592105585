import React, { Component, Fragment } from 'react'
import './Home.css'

export default class Home extends Component {
    render(){
        return (
            <Fragment>
                <div className='home'>
                    <p>Welcome <br/>
                     </p>
                </div>
            </Fragment>
        )
    }
}